import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useKeyboardEvent } from '@react-hookz/web';
import { m } from 'framer-motion';
import Link from 'next/link';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

import { Button, IconOnlyButton } from '@/components/common/Buttons';
import { Paragraph } from '@/components/common/MarkUp';
import { zIndex } from '@/components/common/z-index';
import { MultiverseLogo } from '@/components/layout/MultiverseLogo';
import { mobileBreakpoint } from '@/components/layout/NewNavigationBar/DesktopNavLinks';
import { SubNavStandardLayout } from '@/components/layout/NewNavigationBar/SubNavigation/SubNavStandardLayout';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  NavDirectLink,
  NavPanel,
} from '@/components/layout/NewNavigationBar/types';
import type { SanityKeyed } from '@/types/sanity';
import type { Navigation_exType } from '@/types/shared';

interface MobileNavProps {
  navigation: Navigation_exType;
  isAudienceSwitcherEnabled?: boolean;
}

export const MobileNav_ex = ({
  navigation,
  isAudienceSwitcherEnabled,
}: MobileNavProps) => {
  const globalRef = useRef(null);
  const openOffsetRef = useRef(0);

  const [navOpen, setNavOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState<string | null>(null);

  const activeVariant = navOpen ? 'open' : 'close';

  const toggleNav = () => {
    openOffsetRef.current =
      globalRef.current?.getBoundingClientRect()?.top ?? 0;
    setNavOpen((value) => !value);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  useKeyboardEvent('Escape', () => {
    closeNav();
  });

  const { ctaList } = navigation;
  const primaryCTA = ctaList?.[0];
  const secondaryCTA = ctaList?.[1];

  const transitionDuration = 0.3;

  return (
    <>
      <GlobalNav ref={globalRef} isActive={navOpen}>
        <Container>
          <LogoLink
            href="/"
            aria-label="Multiverse home"
            animate={activeVariant}
            initial="close"
          >
            <MultiverseLogo />
          </LogoLink>
          <Nav
            aria-label="main menu"
            id="subnavigation-list"
            animate={activeVariant}
            initial="close"
          >
            <Button
              variant="flat-light"
              icon={navOpen ? 'Close' : 'NavigationMenu'}
              onClick={() => toggleNav()}
              aria-label={navOpen ? 'Close Navigation' : 'Open Navigation'}
              size="md"
            >
              {navOpen ? 'Close' : 'Menu'}
            </Button>
          </Nav>
        </Container>
        <RemoveScroll forwardProps enabled={navOpen}>
          <SubNavContainer
            animate={activeVariant}
            initial="close"
            variants={{
              open: {
                height: `calc(100vh - ${openOffsetRef.current}px )`,
                visibility: 'visible',
              },
              close: {
                height: 0,
                visibility: 'hidden',
              },
            }}
            transition={{ duration: transitionDuration }}
            role="dialog"
            aria-modal={navOpen}
            aria-label="Site Navigation"
          >
            <ItemContainer>
              {navigation?.navItems?.map(
                (item: SanityKeyed<NavPanel> | SanityKeyed<NavDirectLink>) => {
                  return (
                    <RevealerItem key={item._key}>
                      {item._type !== 'navDirectLink' ? (
                        <RevealerControl
                          onClick={() =>
                            setItemOpen((value) =>
                              value === item.title ? null : item.title,
                            )
                          }
                        >
                          <Paragraph size="label-lg">{item.title}</Paragraph>
                          <m.div
                            animate={itemOpen === item.title ? 'open' : 'close'}
                            initial="close"
                            aria-hidden
                            variants={{
                              open: {
                                rotate: '180deg',
                              },
                              close: {
                                rotate: '0deg',
                              },
                            }}
                          >
                            <StyledIconOnlyButton
                              variant={
                                itemOpen === item.title
                                  ? 'flat-dark'
                                  : 'flat-light'
                              }
                              icon="ChevronDown"
                              size="md"
                              itemOpen={itemOpen === item.title}
                            />
                          </m.div>
                        </RevealerControl>
                      ) : (
                        <DirectLink
                          href={blockUrlWithAudience(
                            item.url,
                            navigation.audience,
                            navigation.audience,
                            item.noAudienceToggle,
                            isAudienceSwitcherEnabled,
                          )}
                        >
                          <Paragraph size="label-lg">{item.title}</Paragraph>
                          <DirectLinkIcon
                            variant={
                              itemOpen === item.title
                                ? 'flat-dark'
                                : 'flat-light'
                            }
                            icon="ArrowRight"
                            size="md"
                          />
                        </DirectLink>
                      )}
                      <RevealerPanel
                        animate={itemOpen === item.title ? 'open' : 'close'}
                        initial="close"
                        variants={{
                          open: {
                            height: 'auto',
                          },
                          close: {
                            height: 0,
                          },
                        }}
                      >
                        {item && (
                          <SubNavStandardLayout
                            navItem={item}
                            navigationAudience={navigation.audience}
                            isAudienceSwitcherEnabled={
                              isAudienceSwitcherEnabled
                            }
                          />
                        )}
                      </RevealerPanel>
                    </RevealerItem>
                  );
                },
              )}
            </ItemContainer>
          </SubNavContainer>
        </RemoveScroll>
      </GlobalNav>
      {primaryCTA?.buttonText && (
        <CtaContainer
          animate={activeVariant}
          initial="close"
          variants={{
            open: {
              transform: ' translateY(0%)',
              display: 'flex',
            },
            close: {
              transform: 'translateY(100%)',
              transitionEnd: {
                display: 'none',
              },
            },
          }}
        >
          {!!secondaryCTA?.buttonText && (
            <Button
              variant="flat-light"
              href={blockUrlWithAudience(
                secondaryCTA.buttonUrl,
                navigation.audience,
                navigation.audience,
                secondaryCTA.noAudienceToggle,
                isAudienceSwitcherEnabled,
              )}
              aria-label={secondaryCTA?.buttonAccessibleLabel}
              size="lg"
            >
              {secondaryCTA.buttonText}
            </Button>
          )}
          {!!primaryCTA?.buttonText && (
            <StyledPrimaryButton
              variant="solid-light"
              href={blockUrlWithAudience(
                primaryCTA.buttonUrl,
                navigation.audience,
                navigation.audience,
                primaryCTA.noAudienceToggle,
                isAudienceSwitcherEnabled,
              )}
              aria-label={primaryCTA?.buttonAccessibleLabel}
              icon="ArrowRight"
              iconPosition="right"
              size="lg"
            >
              {primaryCTA.buttonText}
            </StyledPrimaryButton>
          )}
        </CtaContainer>
      )}
      <BackgroundOverlay
        animate={activeVariant}
        initial="close"
        variants={{
          open: {
            height: `100vh`,
            visibility: 'visible',
            opacity: 1,
          },
          close: {
            opacity: 0,
            visibility: 'hidden',
          },
        }}
        transition={{ duration: transitionDuration }}
      />
    </>
  );
};

const GlobalNav = styled.header<{ isActive: boolean }>`
  position: sticky;
  top: 0;
  backdrop-filter: blur(24px);
  margin: 0 auto;
  min-width: var(--mobile-min-width);
  max-width: var(--desktop-max-width);
  padding: var(--space-component-sm) 16px var(--space-component-sm) 24px;

  z-index: ${zIndex.navBar};

  transition: background 0.3s;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.8)'};

  @media (min-width: ${mobileBreakpoint}px) {
    display: none;
  }
`;
const Container = styled(m.div)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: space-between;
  z-index: ${zIndex.desktopNav};
`;

const ItemContainer = styled(FocusLock)`
  height: calc(100% - 74px);
  overflow: scroll;
  background: var(--background-subtle);
  position: relative;
  z-index: 7;
`;

const StyledPrimaryButton = styled(Button)`
  margin-left: 12px;
`;

const LogoLink = styled(m(Link))`
  max-width: var(--spacing-1600);
  width: 100%;
  display: block;
  color: var(--decorative-ultraviolet);

  svg {
    display: block;
  }
`;

const SubNavContainer = styled(m.div)`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 56px;
  padding-left: 0px;
  padding-right: 0px;

  @media (min-width: ${mobileBreakpoint}px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  background-color: var(--background-subtle);
  overflow: hidden;
  z-index: 8;
`;

const RevealerItem = styled.div`
  background-color: white;
  border-bottom: 1px solid var(--border-warm-base);
  z-index: 7;
  position: relative;
  &:last-of-type {
    border: none;
  }
`;

const CtaContainer = styled(m.div)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-150);

  display: flex;
  justify-content: center;
  /* margin-top: var(--offset-top, 0); */
  background: var(--background-subtle);
  border-top: 1px solid var(--border-warm-base);
  z-index: 1000;
`;

const NavItemStyling = css`
  all: unset;
  appearance: none;
  box-sizing: border-box;
  border-radius: var(--radius-xl);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;

  &:focus-visible {
    outline: 2px solid var(--border-action);
    outline-offset: -4px;
  }
`;

const RevealerControl = styled.div`
  ${NavItemStyling}
`;

const DirectLink = styled(Link)`
  ${NavItemStyling}
`;

const RevealerPanel = styled(m.div)`
  overflow: hidden;
  background: white;

  > * {
    background: var(--surface-warm-base);
    padding: var(--space-page-sm) var(--space-page-xs);
  }
`;

const Nav = styled(m.nav)`
  position: relative;

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

const StyledIconOnlyButton = styled(m(IconOnlyButton))<{ itemOpen: boolean }>`
  border: none;
  background: ${({ itemOpen }) =>
    itemOpen ? 'var(--surface-inverse-base)' : 'var(--surface-warm-strong)'};
`;

const DirectLinkIcon = styled(IconOnlyButton)`
  background: var(--surface-warm-strong);
  border: none;
`;

const BackgroundOverlay = styled(m.div)`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  z-index: 5;
  left: 0;
  opacity: 1;
  height: 100vh;
`;
