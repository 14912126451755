// import * as Accent from '@/components/common/Accent';

import type { TypographySizeTypes } from '@/styles/typography';
import type { DecorativeColourType } from '@/types/colours';
import type { MotionValue } from 'framer-motion';
import type { MutableRefObject } from 'react';

// const motion = {
//   span: createDomMotionComponent('span'),
// };

// const SvgWrapper = styled(m.span, { shouldForwardProp: isPropValid })<{
//   colour: string;
// }>`
//   color: ${(props) => `var(${props.colour})`};
//   position: absolute;

//   svg {
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// `;

/**
 * Highlight
 *
 * Each highlight svg needs to render slightly differently.
 * All dynamic sizing is controlled within Framer Motion so that changes
 * to sizing bypass the normal react render methods and allow us to
 * independently animate the svgs in the future
 *
 */

interface HighlightProps {
  style: 'straight';
  colour?: DecorativeColourType;
  layoutRef: MutableRefObject<{
    width: MotionValue<any>;
    height: MotionValue<any>;
    left: MotionValue<any>;
    top: MotionValue<any>;
    opacity: MotionValue<any>;
  }>;
  size?: TypographySizeTypes;
}

const Highlight = ({ style }: HighlightProps) => {
  // Uncomment if you have new highlights which requires an svg wrapper
  // const Highlight = ({ style, colour, layoutRef }: HighlightProps) => {
  // const { width, height, left, top, opacity } = layoutRef.current;
  // const colourToken = colour?.token || '--decorative-eclipse';

  switch (style) {
    case 'straight':
      return null;
    // return (
    //   <SvgWrapper
    //     colour={colourToken}
    //     style={{
    //       left: useMotionTemplate`calc(${left}px - 0.1em)`,
    //       width: useMotionTemplate`calc(${width}px + 0.2em)`,
    //       top,
    //       height,
    //       opacity,
    //       transformOrigin: 'center',
    //     }}
    //   >
    //     <Accent.Highlight variant="straight" />
    //   </SvgWrapper>
    // );
    default:
      return null;
  }
};

export { Highlight };
