import styled from '@emotion/styled';

import { bodyLarge, subheadingSmall } from '@/styles/typography';

import type { componentSpacingOptions, Spacing } from '@/types/measures';
import type { ReactNode } from 'react';

export interface BulletPointsProps {
  pointColour?:
    | '--decorative-ultraviolet'
    | '--icon-inverse-warm-base'
    | '--icon-warm-base';
  paragraphFontSize?: 'sm' | 'md'; // 'md' might be for a hero with larger text
  children?: ReactNode;
  className?: string;
  bulletPointStyle?: string;
  paddingBetween?: Spacing | componentSpacingOptions;
}

const BulletPoints = ({
  pointColour = '--decorative-ultraviolet',
  paragraphFontSize = 'sm',
  children,
  className,
  bulletPointStyle = 'check',
  paddingBetween = '--spacing-xx-small',
}: BulletPointsProps) => (
  <BulletPointsContainer
    className={className}
    pointColour={pointColour}
    paragraphFontSize={paragraphFontSize}
    bulletPointStyle={
      bulletPointStyle === 'check'
        ? '/images/bullet-tick.svg'
        : '/images/hexagon.svg'
    }
    paddingBetween={paddingBetween}
  >
    {children}
  </BulletPointsContainer>
);

const BulletPointsContainer = styled.div<{
  pointColour:
    | '--decorative-ultraviolet'
    | '--icon-inverse-warm-base'
    | '--icon-warm-base';
  paragraphFontSize: 'sm' | 'md';
  bulletPointStyle: string;
  paddingBetween: Spacing | componentSpacingOptions;
}>`
  ul {
    li {
      &::before {
        background-color: ${({ pointColour }) => `var(${pointColour})`};
        -webkit-mask: url(${({ bulletPointStyle }) => bulletPointStyle})
          no-repeat center/ 18px;
        mask: url(${({ bulletPointStyle }) => bulletPointStyle}) no-repeat
          center/ 18px;
      }
    }
  }
  ul,
  ol {
    li {
      display: flex;
      margin-left: 0;

      &::before {
        content: '';
        -webkit-mask-size: cover;
        mask-size: cover;
        margin-right: var(--spacing-small);
        background-color: ${({ pointColour }) => `var(${pointColour})`};
        -webkit-mask: url(${({ bulletPointStyle }) => bulletPointStyle})
          no-repeat center/ 20px;
        mask: url(${({ bulletPointStyle }) => bulletPointStyle}) no-repeat
          center/ 20px;
        width: 20px;
        height: 20px;
        min-width: 18px;
        position: relative;
        top: 0;
        margin-right: var(--space-component-lg);
      }

      &:not(:last-child) {
        padding-bottom: ${({ paddingBetween }) => `var(${paddingBetween})`};
      }

      &::before {
        top: 3px;
      }
    }

    a {
      color: currentColor;
    }

    b {
      font-weight: var(--font-weight-medium);
    }

    span,
    li {
      ${({ paragraphFontSize }) =>
        paragraphFontSize === 'sm' ? bodyLarge : subheadingSmall};
    }
  }
`;

export { BulletPoints };
