import { useContext, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useMotionValue } from 'framer-motion';

import { HeadingContainerContext } from './context';

import type { MotionValue } from 'framer-motion';

/**
 * HighlightPlaceholder
 *
 * Each highlight within a heading is substituted with a span.
 * This allows us to track the positioning of the highlight without
 * breaking words if were to include the svgs inline with the text.
 *
 * A mixture of background elements and inline svgs were attempted to simplify
 * the approach, but each had downsides.
 *
 * Background elements restricted our sizing and animation approaches.
 * Inline svgs broke text wrapping which caused issues when highlights spanned words.
 *
 */

const HighlightPlaceholder = ({ children, colour, style, _key: id }) => {
  const ref = useRef<HTMLDivElement>(null);
  const layoutRef = useRef<{
    left: MotionValue;
    width: MotionValue;
    top: MotionValue;
    height: MotionValue;
    opacity: MotionValue;
  }>({
    left: useMotionValue(0),
    top: useMotionValue(0),
    width: useMotionValue(0),
    height: useMotionValue(0),
    opacity: useMotionValue(0),
  });

  const { containerRef, updateAsset } = useContext(HeadingContainerContext);

  useEffect(() => {
    updateAsset((state) => ({
      ...state,
      [`${id}`]: {
        style,
        colour,
        layoutRef,
      },
    }));
  }, [id, style, colour, layoutRef, updateAsset]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const {
          height: newHeight,
          left,
          top,
          width,
        } = ref.current.getBoundingClientRect();

        const { left: containerLeft, top: containerTop } =
          containerRef.current.getBoundingClientRect();

        const fontSize = parseInt(
          window
            .getComputedStyle(ref.current)
            .getPropertyValue('font-size')
            .replace('px', ''),
          10,
        );

        layoutRef.current.left.set(left - containerLeft);
        layoutRef.current.top.set(top - containerTop);
        layoutRef.current.width.set(width);
        layoutRef.current.height.set(newHeight);
        layoutRef.current.opacity.set(newHeight < fontSize * 2 ? 1 : 0);
      }
    };

    let ro;

    if (!!window && 'ResizeObserver' in window) {
      ro = new ResizeObserver(() => handleResize());
      ro.observe(document.scrollingElement);
    } else {
      window.addEventListener('resize', handleResize);
      ro = {
        disconnect: () => window.removeEventListener('resize', handleResize),
      };
    }

    // Fire on first render
    handleResize();

    // Fire when fonts finish loading
    if (document.fonts && document.fonts.ready) {
      document.fonts.ready.then(() => {
        handleResize();
      });
    }

    return () => {
      ro.disconnect();
    };
  }, [colour, id, style, updateAsset, containerRef]);

  return (
    <Span ref={ref} colour={colour} highlightStyle={style}>
      {children}
    </Span>
  );
};

const Span = styled.span<{ highlightStyle; colour }>`
  --highlight-colour: ${(props) =>
    `var(${props?.colour?.token || '--decorative-eclipse'})`};
  ${(props) =>
    props.highlightStyle === 'straight' &&
    css`
      background-color: var(--highlight-colour);
      outline: 2px solid var(--highlight-colour);
      border-radius: var(--radius-50);
      line-height: 1.4;
      white-space: break-spaces;
      outline-offset: 0px;
      border-left: 4px solid var(--highlight-colour);
      border-right: 4px solid var(--highlight-colour);
      border-bottom: 2px solid var(--highlight-colour);
      color: var(--text-strong);
    `}
`;

export { HighlightPlaceholder };
