import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Col } from '@/components/layout/Grid';
import { getTextColour } from '@/lib/colourUtils';

import type { SurfaceColourTokenType } from '@/types/colours';
import type {
  BorderRadius,
  componentSpacingOptions,
  radiusComponentOptions,
  Spacing,
} from '@/types/measures';
import type { Elevation } from '@/types/misc';

interface SurfaceProps {
  padding?: Spacing | componentSpacingOptions | false;
  border?: string;
  borderRadius?: BorderRadius | radiusComponentOptions;
  surfaceColour?: SurfaceColourTokenType;
  elevation?: Elevation;
  fill?: boolean;
  outline?: boolean;
}

export const Surface = styled.div<SurfaceProps>`
  flex: 1;
  overflow: clip;
  container-type: inline-size;
  container-name: surface;

  width: 100%;

  ${({
    borderRadius = '',
    elevation,
    padding = '--spacing-large',
    surfaceColour = '--transparent',
    border = 'none',
    fill = false,
    outline = false,
  }) => css`
    padding: ${padding ? `var(${padding})` : 0};
    background-color: var(${surfaceColour});
    border: ${border};
    border-radius: ${borderRadius ? `var(${borderRadius})` : 0};
    color: var(${getTextColour(surfaceColour)});
    box-shadow: ${elevation ? `var(${elevation})` : 'none'};
    height: ${fill ? '100%' : 'auto'};
    outline: ${outline ? `inset 0 0 0 1px var(${surfaceColour})` : 'none'};
  `}

  ${Col} > & {
    height: 100%;
  }
`;
